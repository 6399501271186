.MuiPickersCalendarHeader-switchHeader > .MuiPickersCalendarHeader-transitionContainer {
  height: 35px;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
}